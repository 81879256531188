<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <!--Tabs information-->
              <ul class="custom-tabs-form">
                <li
                  :class="{'active' : selectedTab === 'general_information','danger': general_information_errors_length > 0}"
                  @click="selectTab('general_information')"
                >
                  <span>{{ $t('general_information') }}</span> <span
                    v-if="general_information_errors_length > 0"
                    class="num-error-style"
                  >{{ general_information_errors_length }}</span>
                </li>
                <li
                  :class="{'active' : selectedTab === 'geographical_location','danger': geographical_location_errors_length > 0}"
                  @click="selectTab('geographical_location')"
                >
                  <span>{{ $t('geographical_location') }}</span> <span
                    v-if="geographical_location_errors_length > 0"
                    class="num-error-style"
                  >{{ geographical_location_errors_length }}</span>
                </li>
                <li
                  :class="{'active' : selectedTab === 'property_information','danger': property_information_errors_length > 0}"
                  @click="selectTab('property_information')"
                >
                  <span>{{ $t('property_information') }}</span> <span
                    v-if="property_information_errors_length > 0"
                    class="num-error-style"
                  >{{ property_information_errors_length }}</span>
                </li>
                <li
                  :class="{'active' : selectedTab === 'additional_features','danger': additional_features_errors_length > 0}"
                  @click="selectTab('additional_features')"
                >
                  <span>{{ $t('additional_features') }}</span> <span
                    v-if="additional_features_errors_length > 0"
                    class="num-error-style"
                  >{{ additional_features_errors_length }}</span>
                </li>
                <li
                  :class="{'active' : selectedTab === 'ownership_information','danger': ownership_information_errors_length > 0}"
                  @click="selectTab('ownership_information')"
                >
                  <span>{{ $t('ownership_information') }}</span> <span
                    v-if="ownership_information_errors_length > 0"
                    class="num-error-style"
                  >{{ ownership_information_errors_length }}</span>
                </li>
              </ul>
              <!--General information Tab-->
              <div v-show="selectedTab === 'general_information'">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="ad_side"
                      :name="$t('advertiser_side')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('advertiser_side')"
                      >
                        <v-select
                          v-model="form.ad_side"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.ad_side"
                          :reduce="item => item.value"
                          :placeholder="$t('advertiser_side')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="advertiser_character"
                      :name="$t('advertiser_status')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('advertiser_status')"
                      >
                        <v-select
                          v-model="form.advertiser_character"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.advertiser_character"
                          :reduce="item => item.value"
                          :placeholder="$t('advertiser_status')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('authorization_number')"
                      vid="authorization_no"
                    >
                      <b-form-group
                        :label="$t('authorization_number')"
                      >
                        <Cleave
                          id="authorization_number"
                          v-model="form.authorization_no"
                          placeholder="000-00-0000"
                          :options="cleaveOptions"
                          :row="false"
                          class="form-control"
                          :mask="'999-99-9999'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.advertiser_character === 'broker'"
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('authorization_no_authority')"
                      vid="authorization_no_authority"
                      :rules="form.advertiser_character === 'broker' ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('authorization_no_authority')"
                      >
                        <Cleave
                          id="authorization_no_authority"
                          v-model="form.authorization_no_authority"
                          placeholder="000-00-0000"
                          :options="cleaveOptions"
                          :row="false"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('advertiser_phone')"
                      vid="advertiser_phone"
                      :rules="{
                        required: true,
                        numeric: true,
                        regex: /^(?:00|\+)[0-9\s.\-]{10,14}$/,
                      }"
                    >
                      <b-form-group>
                        <template #label>
                          <span>{{ $t('advertiser_phone') }} ( 009999999999 )</span>
                        </template>
                        <b-form-input
                          v-model="form.advertiser_phone"
                          :placeholder="$t('phone_number_country_code')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('advertiser_email')"
                      vid="advertiser_email"
                      rules="email|required"
                    >
                      <b-form-group
                        :label="$t('advertiser_email')"
                      >
                        <b-form-input
                          v-model="form.advertiser_email"
                          type="email"
                          placeholder="you@example.com"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('advertiser_name')"
                      vid="advertiser_name"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('advertiser_name')"
                      >
                        <b-form-input
                          v-model="form.advertiser_name"
                          :placeholder="$t('enter_name')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="advertiser_type"
                      :name="$t('advertiser_type')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('advertiser_type')"
                      >
                        <v-select
                          v-model="form.advertiser_type"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.advertiser_type"
                          :reduce="item => item.value"
                          :placeholder="$t('advertiser_type')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="the_main_type_of_ad"
                      :name="$t('the_main_type_of_ad')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('the_main_type_of_ad')"
                      >
                        <v-select
                          v-model="form.the_main_type_of_ad"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.real_estate_main_type"
                          :reduce="item => item.value"
                          :placeholder="$t('the_main_type_of_ad')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="status"
                      :name="$t('status')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('status')"
                      >
                        <v-select
                          v-model="form.status"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.status"
                          :reduce="item => item.value"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="the_sub_type_of_ad"
                      :name="$t('the_sub_type_of_ad')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('the_sub_type_of_ad')"
                      >
                        <v-select
                          v-model="form.the_sub_type_of_ad"
                          :dir="$store.state.appConfig.layout.direction"
                          label="label"
                          :options="lists.real_estate_sub_type"
                          :reduce="item => item.value"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('ad_recognition')"
                      vid="ad_recognition"
                    >
                      <b-form-group
                        :label="$t('ad_recognition')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.is_featured"
                            name="is_featured"
                            value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.is_featured"
                            name="is_featured"
                            value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center mt-2"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('geographical_location')"
                    >
                      {{ $t('next') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <!--Geographical location Tab-->
              <div v-show="selectedTab === 'geographical_location'">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2 mt-2"
                  >
                    <label
                      style="font-weight: 500;font-size: 16px"
                      class="mb-1"
                    >
                      {{ $t('Choose the address from the map or fill in the fields below') }}
                    </label>
                    <GmapMap
                      map-type-id="terrain"
                      :center="center"
                      :zoom="8"
                      style="height: 400px"
                      @click="handleMapClick"
                    >
                      <GmapMarker :options="{ position: center }" />
                    </GmapMap>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="region_id"
                      :name="$t('region')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('region')"
                      >
                        <v-select
                          v-model="form.region_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name_label"
                          :options="regions"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="city_id"
                      :name="$t('city')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('city')"
                      >
                        <v-select
                          v-model="form.city_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name_label"
                          :options="cities"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="district_id"
                      :name="$t('district')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('district')"
                      >
                        <v-select
                          v-model="form.district_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name_label"
                          :options="districts"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('street_name')"
                      vid="street_name"
                    >
                      <b-form-group
                        :label="$t('street_name')"
                      >
                        <b-form-input
                          v-model="form.street_name"
                          :placeholder="$t('street_name')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('latitude_coordinates')"
                      vid="latitude"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('latitude_coordinates')"
                      >
                        <b-form-input
                          v-model="form.latitude"
                          :placeholder="$t('latitude_coordinates')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('longitude_coordinates')"
                      vid="longitude"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('longitude_coordinates')"
                      >
                        <b-form-input
                          v-model="form.longitude"
                          :placeholder="$t('longitude_coordinates')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="youtube_link"
                      vid="youtube_link"
                    >
                      <b-form-group
                        :label="$t('video_link')"
                      >
                        <b-input-group
                          prepend="URL"
                        >
                          <b-form-input
                            v-model="form.youtube_link"
                            :placeholder="$t('video_link')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="images"
                      vid="images"
                    >
                      <div>
                        <div class="d-flex justify-content-between align-items-center">
                          <h4>{{ $t('attach_photos') }}</h4>
                          <b-form-file
                            id="images"
                            class="hidden"
                            multiple
                            accept="image/*"
                            @change="uploader($event,'images')"
                          />
                          <label
                            for="images"
                            class="btn btn-primary"
                          >
                            {{ $t('attach') }}
                          </label>
                        </div>
                        <ul
                          v-if="images_arr.length"
                          class="list-uploader"
                        >
                          <li
                            v-for="(file,index) in images_arr"
                            :key="file.name"
                          >
                            <div class="image-box">
                              <div class="img-container">
                                <img
                                  :src="file.url"
                                  alt="Image"
                                >
                              </div>
                              <h4>
                                {{ file.name }}
                              </h4>
                            </div>
                            <button
                              type="button"
                              class="btn bg-danger"
                              @click="handleDeleteImage(index,'images_arr')"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ffffff"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                                />
                              </svg>
                            </button>
                          </li>
                        </ul>
                        <h5
                          v-if="!images_arr.length"
                          class="no-files"
                        >
                          {{ $t('No files attached') }}
                        </h5>
                        <small
                          v-if="!images_arr.length"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="engineering_plans"
                      vid="engineering_plans"
                    >
                      <div>
                        <div class="d-flex justify-content-between align-items-center">
                          <h4>{{ $t('attach_plans_for_the_property') }}</h4>
                          <b-form-file
                            id="engineering_plans"
                            class="hidden"
                            multiple
                            accept="image/*, application/pdf"
                            @change="uploader($event,'engineering_plans')"
                          />
                          <label
                            for="engineering_plans"
                            class="btn btn-primary"
                          >
                            {{ $t('attach') }}
                          </label>
                        </div>
                        <ul
                          v-if="files_engineering_plans.length"
                          class="list-uploader"
                        >
                          <li
                            v-for="(file,index) in files_engineering_plans"
                            :key="file.name"
                          >
                            <div class="image-box">
                              <div class="img-container">
                                <img
                                  v-if="isPDF(file.type)"
                                  src="@/assets/images/file.svg"
                                  alt="Image"
                                  class="pdf"
                                  style="width: 70%"
                                >
                                <img
                                  v-else
                                  :src="file.url"
                                  alt="Image"
                                >
                              </div>
                              <h4>
                                {{ file.name }}
                              </h4>
                            </div>
                            <button
                              type="button"
                              class="btn bg-danger"
                              @click="handleDeleteImage(index,'files_engineering_plans')"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ffffff"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                                />
                              </svg>
                            </button>
                          </li>
                        </ul>
                        <h5
                          v-if="!files_engineering_plans.length"
                          class="no-files"
                        >
                          {{ $t('No files attached') }}
                        </h5>
                        <small
                          v-if="!files_engineering_plans.length"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="brochure"
                      vid="documents"
                    >
                      <div>
                        <div class="d-flex justify-content-between align-items-center">
                          <h4>{{ $t('brochure') }}</h4>
                          <b-form-file
                            id="documents"
                            class="hidden"
                            accept="application/pdf"
                            @change="uploader($event,'documents')"
                          />
                          <label
                            for="documents"
                            class="btn btn-primary"
                          >
                            {{ $t('attach') }}
                          </label>
                        </div>
                        <ul
                          v-if="files_documents.length"
                          class="list-uploader"
                        >
                          <li
                            v-for="(file,index) in files_documents"
                            :key="file.name"
                          >
                            <div class="image-box">
                              <div class="img-container">
                                <img
                                  src="@/assets/images/file.svg"
                                  alt="Image"
                                  class="pdf"
                                  style="width: 70%"
                                >
                              </div>
                              <h4>
                                {{ file.name }}
                              </h4>
                            </div>
                            <button
                              type="button"
                              class="btn bg-danger"
                              @click="handleDeleteImage(index,'files_documents')"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ffffff"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                                />
                              </svg>
                            </button>
                          </li>
                        </ul>
                        <h5
                          v-if="!files_documents.length"
                          class="no-files"
                        >
                          {{ $t('No files attached') }}
                        </h5>
                        <small
                          v-if="!files_documents.length"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </div>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center mt-2 gap-20"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('general_information')"
                    >
                      {{ $t('previous') }}
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('property_information')"
                    >
                      {{ $t('next') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <!--Property information Tab-->
              <div v-show="selectedTab === 'property_information'">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="usage_id"
                      :name="$t('usage_id')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('usage_id')"
                      >
                        <v-select
                          v-model="form.usage_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name"
                          :options="usage"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="type_id"
                      :name="$t('type_id')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('type_id')"
                      >
                        <v-select
                          v-model="form.type_id"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name"
                          :options="type_id"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="real_estate_facade"
                      :name="$t('real_estate_facade')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('real_estate_facade')"
                      >
                        <v-select
                          v-model="form.real_estate_facade"
                          :dir="$store.state.appConfig.layout.direction"
                          label="key"
                          :options="constants.facades"
                          :reduce="item => item.value"
                          :placeholder="$t('select')"
                          multiple
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('real_distance')"
                      vid="distance"
                      rules="numeric"
                    >
                      <b-form-group
                        :label="$t('real_distance')"
                      >
                        <b-form-input
                          v-model="form.distance"
                          :placeholder="$t('real_distance')"
                          autocomplete="off"
                          type="number"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('width')"
                      vid="width"
                      rules="required|numeric"
                    >
                      <b-form-group
                        :label="$t('width')"
                      >
                        <b-form-input
                          v-model="form.width"
                          :placeholder="$t('width')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('property_length')"
                      vid="property_length"
                      rules="numeric"
                    >
                      <b-form-group
                        :label="$t('property_length')"
                      >
                        <b-form-input
                          v-model="form.limits_and_lengths_of_the_property"
                          :placeholder="$t('property_length')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('land_no')"
                      vid="land_no"
                      rules="numeric"
                    >
                      <b-form-group
                        :label="$t('land_no')"
                      >
                        <b-form-input
                          v-model="form.land_no"
                          :placeholder="$t('land_no')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('floor_no')"
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('floor_no')"
                      vid="floor_no"
                      :rules="shouldRenderValidation('floor_no') ? 'required|numeric':'numeric'"
                    >
                      <b-form-group
                        :label="$t('floor_no')"
                      >
                        <b-form-input
                          v-model="form.floor_no"
                          :placeholder="$t('floor_no')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('unit_number')"
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('unit_number')"
                      vid="unit_number"
                      :rules="shouldRenderValidation('unit_number') ? 'required|numeric':'numeric'"
                    >
                      <b-form-group
                        :label="$t('unit_number')"
                      >
                        <b-form-input
                          v-model="form.unit_number"
                          :placeholder="$t('unit_number')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('planned_no')"
                      vid="planned_no"
                      rules="numeric"
                    >
                      <b-form-group
                        :label="$t('planned_no')"
                      >
                        <b-form-input
                          v-model="form.planned_no"
                          :placeholder="$t('planned_no')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('number_of_units')"
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('number_of_units')"
                      vid="number_of_units"
                      :rules="shouldRenderValidation('number_of_units') ? 'required|numeric':'numeric'"
                    >
                      <b-form-group
                        :label="$t('number_of_units')"
                      >
                        <b-form-input
                          v-model="form.number_of_units"
                          :placeholder="$t('number_of_units')"
                          autocomplete="off"
                          type="number"
                          class="arrow-input"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('street_width')"
                      vid="street_width"
                      rules="required|numeric"
                    >
                      <b-form-group
                        :label="$t('street_width')"
                      >
                        <b-form-input
                          v-model="form.street_width"
                          :placeholder="$t('street_width')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('construction_period')"
                      vid="construction_period"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('construction_period')"
                      >
                        <v-select
                          v-model="form.construction_period"
                          :dir="$store.state.appConfig.layout.direction"
                          label="key"
                          :options="constants.years"
                          :reduce="item => item.value"
                          :placeholder="$t('select')"
                          max-height="200px"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('selling_price')"
                      vid="selling_price"
                      rules="required|numeric"
                    >
                      <b-form-group
                        :label="$t('selling_price')"
                      >
                        <b-form-input
                          v-model="form.selling_price"
                          :placeholder="$t('selling_price')"
                          autocomplete="off"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.the_main_type_of_ad === 'offer'"
                    md="6"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('selling_meter_price')"
                      vid="selling_meter_price"
                    >
                      <b-form-group
                        :label="$t('selling_meter_price')"
                      >
                        <b-form-input
                          v-model="form.selling_meter_price"
                          :placeholder="$t('selling_meter_price')"
                          autocomplete="off"
                          type="number"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    <b-row>
                      <b-col
                        v-if="shouldRenderValidation('kitchens') || shouldRenderValidation('bedrooms') || shouldRenderValidation('bathrooms') || shouldRenderValidation('boards') || shouldRenderValidation('living_rooms')"
                        cols="12"
                        class="mb-2"
                      >
                        <h3>
                          {{ $t('Type_and_number_of_rooms') }}
                        </h3>
                      </b-col>
                      <b-col
                        v-if="shouldRenderValidation('kitchens')"
                        md="4"
                        lg="3"
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('kitchen_number')"
                          vid="kitchens"
                          :rules="shouldRenderValidation('kitchens') ? 'required|numeric':'numeric'"
                        >
                          <b-form-group
                            :label="$t('kitchen_number')"
                          >
                            <b-form-input
                              v-model="form.kitchens"
                              :placeholder="$t('kitchen_number')"
                              autocomplete="off"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="shouldRenderValidation('bedrooms')"
                        md="4"
                        lg="3"
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('bedrooms_no')"
                          vid="bedrooms"
                          :rules="shouldRenderValidation('bedrooms') ? 'required|numeric':'numeric'"
                        >
                          <b-form-group
                            :label="$t('bedrooms_no')"
                          >
                            <b-form-input
                              v-model="form.bedrooms"
                              :placeholder="$t('bedrooms_no')"
                              autocomplete="off"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="shouldRenderValidation('bathrooms')"
                        md="4"
                        lg="3"
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('bathrooms_no')"
                          vid="bathrooms"
                          :rules="shouldRenderValidation('bathrooms') ? 'required|numeric':'numeric'"
                        >
                          <b-form-group
                            :label="$t('bathrooms_no')"
                          >
                            <b-form-input
                              v-model="form.bathrooms"
                              :placeholder="$t('bathrooms_no')"
                              autocomplete="off"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="shouldRenderValidation('boards')"
                        md="4"
                        lg="3"
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('boards_no')"
                          vid="boards"
                          :rules="shouldRenderValidation('boards') ? 'required|numeric':'numeric'"
                        >
                          <b-form-group
                            :label="$t('boards_no')"
                          >
                            <b-form-input
                              v-model="form.boards"
                              :placeholder="$t('boards_no')"
                              autocomplete="off"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="shouldRenderValidation('living_rooms')"
                        md="4"
                        lg="3"
                        cols="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('number_council')"
                          vid="living_rooms"
                          :rules="shouldRenderValidation('living_rooms') ? 'required|numeric':'numeric'"
                        >
                          <b-form-group
                            :label="$t('number_council')"
                          >
                            <b-form-input
                              v-model="form.living_rooms"
                              :placeholder="$t('number_council')"
                              autocomplete="off"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center mt-2 gap-20"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('geographical_location')"
                    >
                      {{ $t('previous') }}
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('additional_features')"
                    >
                      {{ $t('next') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <!--Additional features Tab-->
              <div v-show="selectedTab === 'additional_features'">
                <b-row class="align-items-end">
                  <b-col
                    v-if="shouldRenderValidation('furnished')"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('furnished')"
                      vid="furnished"
                      :rules="shouldRenderValidation('furnished') ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('furnished_label')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40 flex-wrap"
                        >
                          <b-form-radio
                            v-model="form.furnished"
                            name="is_furnished"
                            value="furnished"
                          >
                            {{ $t('furnished') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.furnished"
                            name="is_furnished"
                            value="semi_furnished"
                          >
                            {{ $t('semi_furnished') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.furnished"
                            name="is_furnished"
                            value="unfurnished"
                          >
                            {{ $t('unfurnished') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.type_id === 1"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('kitchen_is_structure')"
                      vid="kitchen_is_structure"
                      :rules="form.type_id === 1 ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('kitchen_is_structure')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.kitchen_is_structure"
                            name="is_kitchen"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.kitchen_is_structure"
                            name="is_kitchen"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('air_conditioning')"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('air_conditioning')"
                      vid="air_conditioning"
                      :rules="shouldRenderValidation('air_conditioning') ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('air_conditioning')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.air_conditioning"
                            name="is_air_conditioning"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.air_conditioning"
                            name="is_air_conditioning"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-show="((form.air_conditioning === 1) && shouldRenderValidation('air_conditioning_answer'))"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('air_conditioning_answer')"
                      vid="air_conditioning_answer"
                      :rules="((form.air_conditioning === 1) && shouldRenderValidation('air_conditioning_answer')) ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('air_conditioning_answer')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.air_conditioning_answer"
                            name="is_air_conditioning_answer"
                            :value="'central'"
                          >
                            {{ $t('central') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.air_conditioning_answer"
                            name="is_air_conditioning_answer"
                            :value="'normal'"
                          >
                            {{ $t('normal') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('electricity')"
                      vid="electricity"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('electricity')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.electricity"
                            name="is_electricity"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.electricity"
                            name="is_electricity"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('water')"
                      vid="water"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('water')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.water"
                            name="is_water"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.water"
                            name="is_water"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('internet')"
                      vid="internet"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('internet')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.internet"
                            name="is_internet"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.internet"
                            name="is_internet"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('balcony')"
                      vid="balcony"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('balcony')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.extra_fields[0].value"
                            name="is_balcony"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.extra_fields[0].value"
                            name="is_balcony"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('elevator')"
                    cols="12"
                    md="6"
                  >
                    {{ $t('Elevators_and_their_numbers') }}
                    <div class="custom-flex-fields">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('elevator')"
                        vid="elevator"
                        :rules="shouldRenderValidation('elevator') ? 'required':'' "
                      >
                        <b-form-group
                          :label="$t('is_there')"
                        >
                          <div
                            class="border rounded-sm p-1 d-flex custom-gap-40"
                          >
                            <b-form-radio
                              v-model="form.elevator"
                              name="is_elevator"
                              :value="1"
                            >
                              {{ $t('yes') }}
                            </b-form-radio>
                            <b-form-radio
                              v-model="form.elevator"
                              name="is_elevator"
                              :value="2"
                            >
                              {{ $t('no') }}
                            </b-form-radio>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Elevators_numbers')"
                        vid="elevator_count"
                        :rules="((form.elevator === 1) && shouldRenderValidation('elevator_count')) ? 'required|numeric':'numeric'"
                      >
                        <b-form-group
                          :label="$t('Elevators_numbers')"
                        >
                          <b-form-input
                            v-model="form.elevator_count"
                            autocomplete="off"
                            type="number"
                            placeholder="0"
                            style="height: 50px"
                            :disabled="form.elevator === 2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    v-if="shouldRenderValidation('parking')"
                    cols="12"
                    md="6"
                  >
                    {{ $t('positions and their numbers') }}
                    <div class="custom-flex-fields">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('parking')"
                        vid="parking"
                        :rules="shouldRenderValidation('parking') ? 'required':'' "
                      >
                        <b-form-group
                          :label="$t('is_there')"
                        >
                          <div
                            class="border rounded-sm p-1 d-flex custom-gap-40"
                          >
                            <b-form-radio
                              v-model="form.parking"
                              name="is_parking"
                              :value="1"
                            >
                              {{ $t('yes') }}
                            </b-form-radio>
                            <b-form-radio
                              v-model="form.parking"
                              name="is_parking"
                              :value="2"
                            >
                              {{ $t('no') }}
                            </b-form-radio>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('parking_count')"
                        vid="parking_count"
                        :rules="((form.parking === 1) && shouldRenderValidation('parking_count')) ? 'required|numeric':'numeric'"
                      >
                        <b-form-group
                          :label="$t('parking_count')"
                        >
                          <b-form-input
                            v-model="form.parking_count"
                            autocomplete="off"
                            type="number"
                            placeholder="0"
                            style="height: 50px"
                            :disabled="form.parking === 2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    v-if="form.air_conditioning !== 1"
                    cols="12"
                    md="6"
                  />
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="amenities"
                      :name="$t('amenities')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('amenities')"
                      >
                        <v-select
                          v-model="form.amenities"
                          :dir="$store.state.appConfig.layout.direction"
                          label="name"
                          multiple
                          :options="amenities"
                          :reduce="item => item.id"
                          :placeholder="$t('select')"
                        >
                          <template #no-options>
                            {{ $t('No options') }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center mt-2 gap-20"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('property_information')"
                    >
                      {{ $t('previous') }}
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('ownership_information')"
                    >
                      {{ $t('next') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <!--Ownership information Tab-->
              <div v-show="selectedTab === 'ownership_information'">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('mortgage_or_restriction_question')"
                      vid="mortgage_or_restriction_question"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('mortgage_or_restriction_question')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.mortgage_or_restriction_question"
                            name="is_mortgage_or_restriction_question"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.mortgage_or_restriction_question"
                            name="is_mortgage_or_restriction_question"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('information_may_affect_question')"
                      vid="information_may_affect_question"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('information_may_affect_question')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.information_may_affect_question"
                            name="is_information_may_affect_question"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.information_may_affect_question"
                            name="is_information_may_affect_question"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Rights and obligations')"
                      vid="rights_and_obligations_question"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Rights and obligations')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.rights_and_obligations_question"
                            name="is_rights_and_obligations_question"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.rights_and_obligations_question"
                            name="is_rights_and_obligations_question"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('disputes_question')"
                      vid="disputes_question"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('disputes_question')"
                      >
                        <div
                          class="border rounded-sm p-1 d-flex custom-gap-40"
                        >
                          <b-form-radio
                            v-model="form.disputes_question"
                            name="is_disputes_question"
                            :value="1"
                          >
                            {{ $t('yes') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.disputes_question"
                            name="is_disputes_question"
                            :value="2"
                          >
                            {{ $t('no') }}
                          </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.mortgage_or_restriction_question === 1"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('mortgage_details')"
                      vid="mortgage_details"
                      :rules="form.mortgage_or_restriction_question === 1 ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('mortgage_details')"
                      >
                        <b-form-textarea
                          v-model="form.mortgage_restriction_prevents_answer"
                          :placeholder="$t('mortgage_details')"
                          autocomplete="off"
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.information_may_affect_question === 1"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('State the information')"
                      vid="information_may_affect_answer"
                      :rules="form.information_may_affect_question === 1 ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('State the information')"
                      >
                        <b-form-textarea
                          v-model="form.information_may_affect_answer"
                          :placeholder="$t('State the information')"
                          autocomplete="off"
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.rights_and_obligations_question === 1"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Rights_and_obligations')"
                      vid="rights_and_obligations_answer"
                      :rules="form.rights_and_obligations_question === 1 ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('Rights_and_obligations')"
                      >
                        <b-form-textarea
                          v-model="form.rights_and_obligations_answer"
                          :placeholder="$t('Rights_and_obligations')"
                          autocomplete="off"
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="form.disputes_question === 1"
                    cols="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('disputes_answer')"
                      vid="disputes_answer"
                      :rules="form.disputes_question === 1 ? 'required':''"
                    >
                      <b-form-group
                        :label="$t('disputes_answer')"
                      >
                        <b-form-textarea
                          v-model="form.disputes_answer"
                          :placeholder="$t('disputes_answer')"
                          autocomplete="off"
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-center mt-2 gap-20"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="selectTab('additional_features')"
                    >
                      {{ $t('previous') }}
                    </b-button>
                    <LoadingButton />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import { BFormRadio } from 'bootstrap-vue'
import { googleKey } from '@/services/url.service'

import formMixin from '@/mixins/formMixin'
import 'leaflet/dist/leaflet.css'
// eslint-disable-next-line import/order
import Cleave from 'vue-cleave-component'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormRadio,
    Cleave,
  },
  mixins: [formMixin],
  data() {
    return {
      cleaveOptions: {
        blocks: [3, 2, 4],
        delimiter: '-',
        numericOnly: true,
      },
      center: {
        lat: 24.774265,
        lng: 46.738586,
      },
      selectedTab: 'property_information',
      websiteUrl: 'https://aqaratic-api.digitalfuturedev.com/api/v1',
      lists: {},
      modelOptions: {},
      usage: [],
      type_id: [],
      amenities: [],
      regions: [],
      cities: [],
      districts: [],
      images_arr: [],
      files_engineering_plans: [],
      files_documents: [],
      form: {
        status: null,
        the_sub_type_of_ad: null,
        ad_side: null,
        advertiser_character: null,
        authorization_no: null,
        authorization_no_authority: null,
        advertiser_phone: null,
        advertiser_email: null,
        advertiser_name: null,
        advertiser_type: null,
        the_main_type_of_ad: null,
        is_featured: null,
        // Tab 2
        region_id: null,
        city_id: null,
        district_id: null,
        latitude: null,
        longitude: null,
        street_name: null,
        youtube_link: null,
        images: [],
        engineering_plans: [],
        documents: [],
        // Tab 3
        usage_id: null,
        type_id: null,
        real_estate_facade: [],
        distance: null,
        width: null,
        limits_and_lengths_of_the_property: null,
        land_no: null,
        floor_no: null,
        unit_number: null,
        planned_no: null,
        number_of_units: null,
        street_width: null,
        construction_period: null,
        selling_price: null,
        selling_meter_price: null,
        kitchens: null,
        bedrooms: null,
        bathrooms: null,
        boards: null,
        living_rooms: null,
        // Tab 4
        furnished: null,
        kitchen_is_structure: null,
        air_conditioning: null,
        air_conditioning_answer: '',
        electricity: null,
        water: null,
        internet: null,
        elevator: null,
        elevator_count: null,
        parking: null,
        parking_count: null,
        amenities: [],
        extra_fields: [
          {
            id: 'balcony',
            label: 'Balcony',
            value: null,
          },
        ],
        // Tab 5
        mortgage_or_restriction_question: 2,
        information_may_affect_question: 2,
        rights_and_obligations_question: 2,
        disputes_question: 2,
        mortgage_restriction_prevents_answer: null,
        information_may_affect_answer: null,
        rights_and_obligations_answer: null,
        disputes_answer: null,
      },
      general_information_errors_length: 0,
      geographical_location_errors_length: 0,
      property_information_errors_length: 0,
      additional_features_errors_length: 0,
      ownership_information_errors_length: 0,
      ownership_information_errors: [
        'mortgage_or_restriction_question',
        'information_may_affect_question',
        'rights_and_obligations_question',
        'disputes_question',
        'mortgage_details',
        'information_may_affect_answer',
        'rights_and_obligations_answer',
        'disputes_answer',
      ],
      additional_features_errors: [
        'furnished',
        'kitchen_is_structure',
        'air_conditioning',
        'air_conditioning_answer',
        'electricity',
        'water',
        'internet',
        'balcony',
        'elevator',
        'parking',
        'amenities',
      ],
      property_information_errors: [
        'usage_id',
        'type_id',
        'real_estate_facade',
        'distance',
        'width',
        'property_length',
        'land_no',
        'floor_no',
        'unit_number',
        'planned_no',
        'number_of_units',
        'street_width',
        'construction_period',
        'selling_price',
        'selling_meter_price',
        'kitchens',
        'bedrooms',
        'bathrooms',
        'boards',
        'living_rooms',
      ],
      geographical_location_errors: [
        'region_id',
        'city_id',
        'district_id',
        'latitude',
        'longitude',
      ],
      general_information_errors: [
        'ad_side',
        'advertiser_character',
        'advertiser_phone',
        'advertiser_email',
        'advertiser_name',
        'advertiser_type',
        'the_main_type_of_ad',
        'status',
        'the_sub_type_of_ad',
      ],
      // form: {
      //   status: null,
      //   ad_side: 'individual',
      //   advertiser_email: 'i.mousa@digitalfuture.sa',
      //   advertiser_character: 'owner',
      //   authorization_no_authority: '111-11-1111',
      //   authorization_no: '111-11-1111',
      //   advertiser_type: 'real_estate_marketer',
      //   the_main_type_of_ad: 'request',
      //   advertiser_phone: '00970599785056',
      //   is_featured: 1,
      //   advertiser_name: 'Ipsum voluptas aliq',
      //   the_sub_type_of_ad: 'sell',
      //   city_id: 1,
      //   region_id: 2,
      //   district_id: 10100003191,
      //   street_name: null,
      //   latitude: 24.894628,
      //   longitude: 46.712725,
      //   youtube_link: null,
      //   type_id: 11,
      //   usage_id: 2,
      //   real_estate_facade: 'west',
      //   distance: '2',
      //   width: 2,
      //   land_no: '2',
      //   floor_no: 2,
      //   unit_number: 2,
      //   planned_no: '2',
      //   number_of_units: 2,
      //   limits_and_lengths_of_the_property: 2,
      //   street_width: 2,
      //   construction_period: '2',
      //   selling_meter_price: 2,
      //   selling_price: 2,
      //   air_conditioning_answer: 'central',
      //   living_rooms: 2,
      //   bedrooms: 2,
      //   bathrooms: 2,
      //   boards: 2,
      //   kitchens: 2,
      //   furnished: null,
      //   kitchen_is_structure: 2,
      //   air_conditioning: 2,
      //   electricity: 2,
      //   water: 2,
      //   internet: 2,
      //   extra_fields: [
      //     {
      //       id: 'balcony',
      //       label: 'Balcony',
      //       value: null,
      //     }
      //   ],
      //   elevator: 2,
      //   elevator_count: 0,
      //   parking: 2,
      //   parking_count: 0,
      //   amenities: [],
      //   mortgage_or_restriction_question: 2,
      //   mortgage_restriction_prevents_answer: null,
      //   information_may_affect_question: 2,
      //   information_may_affect_answer: null,
      //   rights_and_obligations_question: 2,
      //   rights_and_obligations_answer: null,
      //   disputes_question: 2,
      //   disputes_answer: null,
      //   images: [],
      //   engineering_plans: [],
      //   documents: [],
      // },
      images_files: [],
      engineering_plans_files: [],
      documents_files: [],
      userData: getUserData().userData,
      constants: {},
      selectedId: null,
      columns_real_estate_value: null,
    }
  },
  computed: {
    shouldRenderValidation() {
      return key => this.selectedId && this.selectedId.required && this.selectedId.required.includes(key)
    },
  },
  watch: {
    'form.type_id': {
      deep: true,
      handler(val) {
        const item = this.type_id.filter(el => el.id === val)
        this.selectedId = item ? item[0].columns_real_estate_value : null
      },
    },
    'form.selling_price': {
      handler() {
        this.calculateMeterPrice()
      },
    },
    'form.width': {
      handler() {
        this.calculateDistance()
      },
    },
    'form.limits_and_lengths_of_the_property': {
      handler() {
        this.calculateDistance()
      },
    },
    'form.parking': {
      handler(val) {
        if (val === 2) {
          this.form.parking_count = 0
        }
      },
    },
    'form.elevator': {
      handler(val) {
        if (val === 2) {
          this.form.elevator_count = 0
        }
      },
    },
    'form.region_id': {
      handler(val) {
        this.getCities(val)
      },
    },
    'form.city_id': {
      handler(val) {
        this.getDistrict(val)
      },
    },
    images_arr: {
      handler(val) {
        this.watchFilesChanged('images', val)
      },
    },
    files_engineering_plans: {
      handler(val) {
        this.watchFilesChanged('engineering_plans', val)
      },
    },
    files_documents: {
      handler(val) {
        this.watchFilesChanged('documents', val)
      },
    },
    formErrors: {
      deep: true,
      handler(val) {
        this.getErrorTabLength(val, this.general_information_errors, 'general_information_errors_length')
        this.getErrorTabLength(val, this.geographical_location_errors, 'geographical_location_errors_length')
        this.getErrorTabLength(val, this.property_information_errors, 'property_information_errors_length')
        this.getErrorTabLength(val, this.additional_features_errors, 'additional_features_errors_length')
        this.getErrorTabLength(val, this.ownership_information_errors, 'ownership_information_errors_length')
      },
    },
    form: {
      deep: true,
      handler() {
        setTimeout(() => {
          const formErrors = this.$refs.form.errors
          this.formErrors = formErrors
          this.getErrorTabLength(formErrors, this.general_information_errors, 'general_information_errors_length')
          this.getErrorTabLength(formErrors, this.geographical_location_errors, 'geographical_location_errors_length')
          this.getErrorTabLength(formErrors, this.property_information_errors, 'property_information_errors_length')
          this.getErrorTabLength(formErrors, this.additional_features_errors, 'additional_features_errors_length')
          this.getErrorTabLength(formErrors, this.ownership_information_errors, 'ownership_information_errors_length')
        }, 500)
      },
    },
  },
  created() {
    this.getConstants()
    if (!this.isEdit && this.userData.phone) {
      this.form.advertiser_phone = this.userData.phone
    }
    if (!this.isEdit && this.userData.email) {
      this.form.advertiser_email = this.userData.email
    }
    if (!this.isEdit && this.userData.username) {
      this.form.advertiser_name = this.userData.username
    }
    this.getLists()
    this.getRegion()
    this.axios.get(`${this.websiteUrl}/model-options/usage`)
      .then(res => {
        this.usage = res.data.data
      })
    this.axios.get(`${this.websiteUrl}/model-options/types`)
      .then(res => {
        this.type_id = res.data.data
        // this.columns_real_estate_value = res.data.data
      })
    this.axios.get(`${this.websiteUrl}/model-options/amenities`)
      .then(res => {
        this.amenities = res.data.data
      })
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    calculateDistance() {
      const { width, limits_and_lengths_of_the_property } = this.form
      if (width !== null && limits_and_lengths_of_the_property !== null) {
        this.form.distance = width * limits_and_lengths_of_the_property
      } else {
        this.form.distance = null
      }
    },
    calculateMeterPrice() {
      const { distance, selling_price } = this.form
      if (distance !== null && selling_price !== null) {
        this.form.selling_meter_price = (selling_price / distance).toFixed(2)
      } else {
        this.form.selling_meter_price = null
      }
    },
    getConstants() {
      this.axios.get(`${this.websiteUrl}/real-estate/constants`)
        .then(res => {
          this.constants = res.data.data
        })
    },
    getErrorTabLength(errors, array, v) {
      let errorLength = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const el in errors) {
        if (array.includes(el) && this.formErrors[el].length > 0) {
          // eslint-disable-next-line no-plusplus
          errorLength++
        }
      }
      this[v] = errorLength
    },
    loadData() {
      const params = {
        is_edit: true,
      }
      this.axios.get(`/${this.resource}/${this.resourceId}`, { params })
        .then(res => {
          const { data } = res.data
          this.form = {
            ...this.form,
            ...data,
            amenities: data.amenities.map(el => el.amenity_id),
            published_at: data.published_at ? data.published_at.slice(0, 19) : (new Date()).toISOString().slice(0, 19),
            expiration_date: data.expiration_date ? data.expiration_date.slice(0, 19) : (new Date()).toISOString().slice(0, 19),
            extra_fields: [
              {
                id: 'balcony',
                label: 'Balcony',
                value: (data.extra_fields && data.extra_fields[0]?.value) ? data.extra_fields[0].value : null,
              },
            ],
            air_conditioning_answer: data.air_conditioning_answer ? data.air_conditioning_answer : '',
          }
          this.images_arr = data.images_values
          this.files_engineering_plans = data.engineering_plans_values
          this.files_documents = data.documents ? data.documents : []

          if (this.images_arr.length > 0) {
            this.images_arr.forEach(el => {
              this.images_files.push(el.url)
            })
          }
          if (this.files_engineering_plans.length > 0) {
            this.files_engineering_plans.forEach(el => {
              this.engineering_plans_files.push(el.url)
            })
          }
          if (this.files_documents.length > 0) {
            this.files_documents.forEach(el => {
              this.documents_files.push({
                name: el.name,
                type: el.type,
                url: el.url,
                path: el.name,
              })
            })
          }
          delete this.form.creator
          delete this.form.document
          delete this.form.engineering_plans_values
          delete this.form.type
          delete this.form.usage
          delete this.form.district
          delete this.form.city
          delete this.form.region
          delete this.form.images_values

          this.form.advertiser_phone = this.form.advertiser_phone ? this.form.advertiser_phone : this.userData.phone
          this.form.advertiser_email = this.form.advertiser_email ? this.form.advertiser_email : this.userData.email
          this.form.advertiser_name = this.form.advertiser_name ? this.form.advertiser_name : this.userData.username
        })
    },
    getLists() {
      this.axios.get(`${this.websiteUrl}/get-options`)
        .then(res => {
          this.lists = res.data.data
        })
    },
    isPDF(file) {
      const acceptedExtensions = ['pdf']
      return file.includes(acceptedExtensions)
    },
    watchFilesChanged(key, newVal) {
      if (key === 'images') {
        this.form[key] = []
        // eslint-disable-next-line no-restricted-syntax
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.images_files.length > 0) {
          this.form[key] = this.images_files
        }
      } else if (key === 'engineering_plans') {
        this.form[key] = []
        // eslint-disable-next-line no-restricted-syntax
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.engineering_plans_files.length > 0) {
          this.form[key] = this.engineering_plans_files
        }
      } else if (key === 'documents') {
        this.form[key] = []
        // eslint-disable-next-line no-restricted-syntax
        for (const el of newVal) {
          this.form[key].push(el)
        }
        if (this.isEdit && this.documents_files.length > 0) {
          this.form[key] = this.documents_files
        }
      } else {
        this.form[key] = []
        // eslint-disable-next-line no-restricted-syntax
        for (const el of newVal) {
          this.form[key].push(el)
        }
      }
    },
    selectTab(selected) {
      this.selectedTab = selected
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 100)
    },
    getRegion() {
      this.axios.get('/regions')
        .then(res => {
          this.regions = res.data.data
        })
    },
    getCities(val) {
      this.axios.get(`/cities?region_id=${val}`)
        .then(res => {
          this.cities = res.data.data
        })
    },
    getDistrict(val) {
      this.axios.get(`/districts?city_id=${val}`)
        .then(res => {
          this.districts = res.data.data
        })
    },
    contain(str, substr) {
      const substrings = substr.split(' ')
      return substrings.some(sub => str.indexOf(sub) !== -1)
    },
    async handleMapClick(event) {
      this.center.lat = event.latLng.lat()
      this.center.lng = event.latLng.lng()
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${googleKey()}&language=${this.$i18n.locale}`
        const response = await fetch(url)
        const data = await response.json()
        if (data.status === 'OK') {
          this.center.lat = event.latLng.lat()
          this.center.lng = event.latLng.lng()

          const addressComponents = data.results[2].address_components

          const region = addressComponents.find(c => c.types.includes('administrative_area_level_1')) ? addressComponents.find(c => c.types.includes('administrative_area_level_1')).long_name : null
          const city = addressComponents.find(c => c.types.includes('administrative_area_level_2')) ? addressComponents.find(c => c.types.includes('administrative_area_level_2')).long_name : null
          const district = addressComponents.find(c => c.types.includes('sublocality')) ? addressComponents.find(c => c.types.includes('sublocality')).long_name : null
          // eslint-disable-next-line no-param-reassign,no-return-assign
          this.form.region_id = region ? this.regions.find(r => r.name_label = region).id : null

          if (this.form.region_id) {
            setTimeout(() => {
              this.form.city_id = city ? this.cities.find(c => this.contain(c.name_label, city))?.id : null
            }, 1000)
          }
          setTimeout(() => {
            if (this.form.city_id) {
              setTimeout(() => {
                this.form.district_id = district ? this.districts.find(c => c.name_label.includes(district))?.id : null
              }, 1000)
            }
          }, 1000)
          this.form.street_name = addressComponents.find(c => c.types.includes('route')) ? addressComponents.find(c => c.types.includes('route')).long_name : null
          this.form.latitude = event.latLng.lat()
          this.form.longitude = event.latLng.lng()
        } else {
          console.error(`Geocoding failed with status ${data.status}`)
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleDeleteImage(index, key) {
      this[key].splice(index, 1)
      if (this.isEdit && this.images_files.length > 0) {
        this.images_files.splice(index, 1)
      }
      if (this.isEdit && this.engineering_plans_files.length > 0) {
        this.engineering_plans_files.splice(index, 1)
      }
      if (this.isEdit && this.documents_files.length > 0) {
        this.documents_files.splice(index, 1)
      }
    },
    uploader(e, key) {
      const { files } = e.target
      if (files.length > 0) {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const el of files) {
          formData.append('files[]', el)
        }
        formData.append('type', 'array')
        this.axios.post('/file/uploader/action', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            if (!this.isEdit && key === 'images') {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const el of response.data) {
                this.images_arr.push(el)
              }
            }

            if (this.isEdit && key === 'images') {
              // eslint-disable-next-line no-restricted-syntax
              for (const el of response.data) {
                this.images_files.push(el.name)
                this.images_arr.push(el)
              }
            }

            if (!this.isEdit && key === 'engineering_plans') {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const el of response.data) {
                this.files_engineering_plans.push(el)
              }
            }

            if (this.isEdit && key === 'engineering_plans') {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const el of response.data) {
                this.engineering_plans_files.push(el.name)
                this.files_engineering_plans.push(el)
              }
            }

            if (!this.isEdit && key === 'documents') {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const el of response.data) {
                this.files_documents.push(el)
              }
            }

            if (this.isEdit && key === 'documents') {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              this.documents_files = []
              this.files_documents = []
              // eslint-disable-next-line no-restricted-syntax
              for (const el of response.data) {
                this.documents_files.push(el)
                this.files_documents.push(el)
              }
            }
          })
      }
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      return $form
    },
  },
}
</script>
<style lang="scss" scoped>
.list-uploader {
  list-style: none;
  padding: 0;
  margin-top: 10px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #5d5d5d;
    padding: 10px;

    .image-box {
      display: flex;
      align-items: center;
      gap: 20px;
      width: calc(100% - 110px);

      .img-container {
        width: 70px;
        height: 70px;
        display: grid;
        place-items: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;

          &.pdf {
            object-fit: contain;
          }
        }
      }

      h4 {
        font-size: 14px;
        width: calc(100% - 90px);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.custom-tabs-form {
  display: flex;
  justify-content: center;
  margin-top: 14px;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    gap: 16px;
  }
  > li {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #959cb6;
    margin-right: 24px;
    border-bottom: 2px solid transparent;
    transition: all 0.25s;
    padding-bottom: 8px;
    display: flex;gap: 12px;
    align-items: center;
    width: auto;
    @media (max-width: 991px) {
      margin-right: 0;
      font-size: 14px;
    }
    @media (max-width: 575px) {
      width: calc(50% - 8px);
      justify-content: center;
      border-bottom-color: #524e76;
      > span:not(.num-error-style) {
        width: calc(100% - 36px);
      }
    }
    @media (max-width: 520px) {
      width: 100%;
      justify-content: start;
    }
    &:hover {
      color: var(--primary);
    }

    &.active {
      color: var(--primary);
      border-color: var(--primary);
    }
    &.danger {
      color: var(--red);
      border-color: var(--red);
      &.active {
        border-color: var(--red);
      }
    }
  }
}

.custom-gap-40 {
  gap: 20px 40px;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.no-files {
  border: 1px solid #5d5d5d;
  margin-top: 8px;
  border-radius: 4px;
  padding: 10px;
}

.gap-20 {
  gap: 20px;
}

.custom-flex-fields {
  display: flex;
  gap: 20px;
  @media (max-width: 575px) {
    gap: 10px;
    align-items: baseline;
  }

  span:nth-child(1) {
    width: calc(100% - 160px);
    @media (max-width: 575px) {
      width: calc(100% - 110px);
    }
  }

  span:nth-child(2) {
    width: 140px;
    @media (max-width: 575px) {
      width: 100px;
    }
  }
}
.num-error-style {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color:var(--red);
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: grid;
  place-items: center;
  line-height: 1;
}
</style>
